import "polyfills";

const APPSETTINGS_JSON = `${process.env.PUBLIC_URL}/appsettings.json?v=${Date.now()}`;

(async () => {
  try {
    const resp = await fetch(APPSETTINGS_JSON, {
      credentials: "same-origin"
    });
    if (!resp.ok) throw new Error("Could not fetch settings");
    window.MW_APP_SETTINGS = await resp.json();

    const init = await import("./initialization");
    init.default();
  } catch (error) {
    console.error("Initialization failed", error);
  }
})();
