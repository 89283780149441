import "@babel/polyfill";
import "react-app-polyfill/ie11";

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/da");
  require("@formatjs/intl-pluralrules/dist/locale-data/en");
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/da");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en");
}
